import {
  DELETE_FILE_API_URL,
  DOWNLOAD_FOLDER_API_URL,
  GET_FOLDERS_DATA_API_URL,
  UPLOAD_FILE_API_URL,
} from '../../consts';
import {
  failedDownloadFolder,
  failedUploadFiles,
  getFoldersDataRequestError,
  getFoldersDataRequestStart,
  getFoldersDataRequestSuccess,
  startDownloadFolder,
  startUploadFiles,
  successDownloadFolder,
  successUploadFiles,
} from '../slices/files';
import { errorMessage, successMessage } from '../utils';
import axiosExt from '../utils/request';

export const actionFolderDownload = params => dispatch => {
  
  const { FolderName } = params;
  dispatch(startDownloadFolder(FolderName));
  axiosExt({
    method: 'GET',
    url: DOWNLOAD_FOLDER_API_URL,
    params,
  })
    .then(res => {
      const { data: { Result } = {} } = res;
      dispatch(successDownloadFolder({ data: Result || [], meta: { FolderName } }));
    })
    .catch(error => dispatch(failedDownloadFolder([`Problem with download folder: ${error.message}`])));
};

export const actionFileUpload = requestData => dispatch => {
  const { filesData, FolderID, FolderName } = requestData;
  dispatch(startUploadFiles(FolderName));
  const formData = new FormData();
  filesData.forEach(file => {
    formData.append('files', file);
  });
  axiosExt({
    method: 'POST',
    data: formData,
    url: UPLOAD_FILE_API_URL,
    params: { FolderID, FolderName },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(res => {
      const { data: { count } = {} } = res;
      if (count) {
        dispatch(successUploadFiles({ meta: requestData }));
        successMessage('Files saved successfully');
        dispatch(actionFolderDownload({ FolderID, FolderName }));
      } else {
        errorMessage('Failed saving files');
        dispatch(failedUploadFiles(['Problem with upload files']));
      }
    })
    .catch(error => {
      errorMessage(`Problem with upload files: ${error.message}`);
      dispatch(failedUploadFiles({ data: [`Problem with upload files: ${error.message}`], meta: { FolderName } }));
    });
};

export const actionFileDelete = requestData => dispatch => {
  const { FolderID, FolderName, FileName } = requestData;

  axiosExt({
    method: 'DELETE',
    url: DELETE_FILE_API_URL,
    params: { FolderID, FolderName, FileName },
  })
    .then(() => {
      successMessage('File deleted successfully');
      dispatch(actionFolderDownload({ FolderID, FolderName }));
    })
    .catch(error => {
      errorMessage(`Problem with deleting file: ${error.message}`);
      dispatch(failedUploadFiles({ data: [`Problem with deleting file: ${error.message}`], meta: { FolderName } }));
    });
};

export const actionGetFoldersData = () => dispatch => {
  dispatch(getFoldersDataRequestStart());
  axiosExt({
    method: 'GET',
    url: GET_FOLDERS_DATA_API_URL,
  })
    .then(res => {
      const { data: { Result } = {} } = res;
      if (Result) {
        dispatch(getFoldersDataRequestSuccess({ data: Result }));
      } else {
        dispatch(getFoldersDataRequestError(['Problem with GET folders request']));
      }
    })
    .catch(error => dispatch(getFoldersDataRequestError([`Problem with GET folders request: ${error.message}`])));
};
