export const lateRegistrationsPageValues = {
  defaultPaginationOptions: {
    SizePage: 1000,
    OrderField: 'ID',
    OrderType: 'DESC',
  },
  getListAPIData: {
    method: 'POST',
    url: '/Athena/DailyRegister/QuickLook',
  },
  tableColumns: [
    {
      key: 'Date',
    },
    {
      label: 'Class',
      key: 'ClassName',
    },
    {
      label: 'Teacher',
      key: 'TeacherName',
    },
    {
      label: 'Status',
      key: 'Result',
    },
  ],
};
