import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment/moment';

import {
  ABSENT_ATTENDANCE_API_URL,
  ATTENDANCE_CODES_API_URL,
  GET_ATTENDANCES_LIST_API_URL,
  REGISTER_STATES_FROM_DATE_API_URL,
  UPDATE_ATTENDANCES_LIST_API_URL,
} from '../../consts';
import axiosExt from '../utils/request';

const initialState = {
  attendancesListArray: [],
  attendancesListIsLoading: false,
  isAttendancesUpdateLoading: false,
  attendancesActionCodesArray: [],
  absentAttendanceList: [],
  selectedDate: new Date(),
  registerStates: {},
};

export const actionFetchAttendancesList = createAsyncThunk('actionFetchAttendancesList', async requestData => {
  const response = await axiosExt({
    method: 'POST',
    url: GET_ATTENDANCES_LIST_API_URL,
    data: {
      studentID: 0,
      ...requestData,
    },
  });
  const {
    data: { Result },
  } = response;

  const attendancesListHashList = {};
  Result.forEach(attendance => {
    attendancesListHashList[attendance.ID] = attendance;
  });

  
  return { attendancesListHashList, attendancesListArray: Result };
});

export const actionFetchAttendancesActionCodes = createAsyncThunk('actionFetchAttendancesActionCodes', async () => {
  const response = await axiosExt({
    method: 'GET',
    url: ATTENDANCE_CODES_API_URL,
  });
  const { data: { Result = [] } = {} } = response;
  return Result.sort((a, b) => a.ID - b.ID);
});

export const actionUpdateAttendances = createAsyncThunk('actionUpdateAttendances', async requestData => {
  const response = await axiosExt({
    method: 'POST',
    url: UPDATE_ATTENDANCES_LIST_API_URL,
    data: requestData,
  });
  return response;
});

export const actionGetRegisterStates = createAsyncThunk('actionGetRegisterStates', async requestData => {
  const response = await axiosExt({
    method: 'POST',
    url: REGISTER_STATES_FROM_DATE_API_URL,
    data: requestData,
  });
  const { data: { Result = [] } = {} } = response;
  const statesObj = {};
  Result.forEach(registerStateFromDate => {
    const { date } = registerStateFromDate;
    if (date) {
      statesObj[date] = registerStateFromDate;
    }
  });
  return statesObj;
});

export const actionGetAbsentAttendanceList = createAsyncThunk('actionGetAbsentAttendanceList', async requestData => {
  const todayDate = moment().format('YYYYMMDD');

  const {
    data: { Result },
  } = await axiosExt({
    method: 'GET',
    url: `${ABSENT_ATTENDANCE_API_URL}?date=${todayDate}`,
    data: requestData,
  });
  return Result;
});

export const attendancesListSlice = createSlice({
  initialState,
  name: 'attendancesList',
  extraReducers: builder => {
    // Students attendances
    builder.addCase(actionFetchAttendancesList.pending, state => {
      state.attendancesListIsLoading = true;
    });
    builder.addCase(actionFetchAttendancesList.fulfilled, (state, action) => {
      const { count, attendancesListHashList, attendancesListArray } = action.payload;
      state.attendancesListIsLoading = false;
      state.count = count;
      state.attendancesListHashList = attendancesListHashList;
      state.attendancesListArray = attendancesListArray;
    });
    builder.addCase(actionFetchAttendancesList.rejected, state => {
      state.attendancesListIsLoading = false;
    });

    // Action codes
    builder.addCase(actionFetchAttendancesActionCodes.fulfilled, (state, action) => {
      state.attendancesActionCodesArray = action.payload;
    });

    // Register states (for highlight dates)
    builder.addCase(actionGetRegisterStates.fulfilled, (state, action) => {
      state.registerStates = { ...state.registerStates, ...action.payload };
    });

    // Absent
    builder.addCase(actionGetAbsentAttendanceList.fulfilled, (state, action) => {
      state.absentAttendanceList = action.payload;
    });

    // Submit spinners
    builder.addCase(actionUpdateAttendances.pending, state => {
      state.isAttendancesUpdateLoading = true;
    });
    builder.addCase(actionUpdateAttendances.fulfilled, state => {
      state.isAttendancesUpdateLoading = false;
      state.lastDataUpdateTime = new Date().getTime();
    });
    builder.addCase(actionUpdateAttendances.rejected, state => {
      state.isAttendancesUpdateLoading = false;
    });
  },
  reducers: {
    selectAttendanceDate: (state, action) => {
      state.selectedDate = action.payload;
    },
  },
});

export default attendancesListSlice.reducer;
export const { selectAttendanceDate } = attendancesListSlice.actions;
