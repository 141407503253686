export const attendancePageValues = {
  pageUrl: '/attendance',
  defaultPaginationOptions: {
    SizePage: 1000,
    OrderField: 'ID',
    OrderType: 'DESC',
  },
  tableColumns: [
    {
      key: 'Name', sortable: true
    },
    {
      key: 'Status', sortable: true
    },
    {
      key: 'Notes', sortable: true
    },
  ],
};
