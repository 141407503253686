import {
  ADMISSIONS_SCHOOL_TERMS_API_URL,
  AVAILABLE_MENU_ITEMS_API_URL,
  DASHBOARD_OVERALL_DATA_API_URL,
  FULL_TERMS_API_URL,
  GET_EFFORTS_API_URL,
  GET_PERFORMANCES_API_URL,
  TERMS_FOR_SPECIFIC_DATE_API_URL,
  TRACKING_ASSESSED_LIST_API_URL,
  TRACKING_TARGETS_LIST_API_URL,
} from '../../consts';
import {
  getAvailableMenuItemsHashListSuccess,
  getCurrentAcademicYearSuccess,
  getFullTermsSuccess,
  getRequestEffortsSuccess,
  getRequestPerformancesSuccess,
  getRequestTermsOfEntryError,
  getRequestTermsOfEntryStart,
  getRequestTermsOfEntrySuccess,
  getTrackingAssessedDataRequestError,
  getTrackingAssessedDataRequestStart,
  getTrackingAssessedDataRequestSuccess,
  getTrackingTargetsDataRequestError,
  getTrackingTargetsDataRequestStart,
  getTrackingTargetsDataRequestSuccess,
} from '../slices/common';
import axiosExt from '../utils/request';

export const actionGetTermsForSpecificDate = date => dispatch => {
  axiosExt({
    method: 'POST',
    url: TERMS_FOR_SPECIFIC_DATE_API_URL,
    data: { Date: date },
  }).then(res => {
    const { data: { Result = [] } = {} } = res;
    const academicYears = [];
    const { TermID, StartDate } = Result[0] || {};
    Result.forEach(({ IDAcademicYear }) => {
      if (!academicYears.includes(IDAcademicYear)) {
        academicYears.push(IDAcademicYear);
      }
    });

    dispatch(
      getCurrentAcademicYearSuccess({
        IDsAcademicYear: academicYears.length > 1 ? academicYears.join(',') : academicYears[0],
        TermID,
        StartDate,
      }),
    );
  });
};

export const actionGetTermsOfEntry = () => dispatch => {
  dispatch(getRequestTermsOfEntryStart());
  axiosExt({
    method: 'POST',
    url: ADMISSIONS_SCHOOL_TERMS_API_URL,
    data: {
      IDSchoolTerm: 0,
      SchoolTerm: '',
      IDsAcademicYear: '',
      AcademicYear: '',
      Active: 0
    },
  })
    .then(res => {
      const { data: { Result = [] } = {} } = res;
      const parsedTermsOfEntry = Result.map(termOfEntry => ({
        ID: termOfEntry.IDSchoolTerm,
        Description: `${termOfEntry.SchoolTerm} ${termOfEntry.AcademicYear?.split(' ')?.slice(-1)}`,
        Active: !!termOfEntry.Active
      }));
      dispatch(getRequestTermsOfEntrySuccess(parsedTermsOfEntry));
    })
    .catch(() => {
      dispatch(getRequestTermsOfEntryError());
    });
};

export const actionGetPerformances = () => dispatch => {
  axiosExt({
    method: 'GET',
    url: GET_PERFORMANCES_API_URL,
  }).then(res => {
    const { data: { Result } = {} } = res;
    dispatch(getRequestPerformancesSuccess(Result));
  });
};

export const actionGetEfforts = () => dispatch => {
  axiosExt({
    method: 'GET',
    url: GET_EFFORTS_API_URL,
  }).then(res => {
    const { data: { Result } = {} } = res;
    dispatch(getRequestEffortsSuccess(Result));
  });
};

export const actionGetTrackingTargets = requestData => dispatch => {
  dispatch(getTrackingTargetsDataRequestStart());
  axiosExt({
    method: 'GET',
    data: requestData,
    url: TRACKING_TARGETS_LIST_API_URL,
  })
    .then(res => {
      const { data: { Result } = {} } = res;
      if (Result) {
        dispatch(getTrackingTargetsDataRequestSuccess(Result));
      } else {
        dispatch(getTrackingTargetsDataRequestError(['Problem with Tracking Targets loading']));
      }
    })
    .catch(error => dispatch(getTrackingTargetsDataRequestError([`GET Tracking Targets error: ${error.message}`])));
};

export const actionGetTrackingAssessed = requestData => dispatch => {
  dispatch(getTrackingAssessedDataRequestStart());
  axiosExt({
    method: 'GET',
    data: requestData,
    url: TRACKING_ASSESSED_LIST_API_URL,
  })
    .then(res => {
      const { data: { Result } = {} } = res;
      if (Result) {
        dispatch(getTrackingAssessedDataRequestSuccess(Result));
      } else {
        dispatch(getTrackingAssessedDataRequestError(['Problem with Tracking Assessed loading']));
      }
    })
    .catch(error => dispatch(getTrackingAssessedDataRequestError([`GET Tracking Assessed error: ${error.message}`])));
};

export const actionGetOverallDashboardData = requestData => dispatch => {
  return new Promise(resolve => {
    axiosExt({
      method: 'POST',
      url: DASHBOARD_OVERALL_DATA_API_URL,
      data: requestData,
      hideGlobalErrors: true,
    })
      .then(res => {
        const { data: { Result } = {} } = res;
        resolve(Result);
      })
      .catch(() => {
        resolve('success');
      });
  });
};

export const actionGetFullTerms = () => dispatch => {
  return new Promise(resolve => {
    axiosExt({
      method: 'POST',
      url: FULL_TERMS_API_URL,
      data: {
        // Date: moment().format(DATE_FORMAT),
        Date: '',
      },
      hideGlobalErrors: true,
    })
      .then(res => {
        const { data: { Result } = {} } = res;
        dispatch(getFullTermsSuccess(Result));
        resolve(Result);
      })
      .catch(() => {
        resolve('success');
      });
  });
};

export const actionGetAvailableMenuItemsHashList = Permissions => dispatch => {
  return new Promise(resolve => {
    axiosExt({
      method: 'POST',
      url: AVAILABLE_MENU_ITEMS_API_URL,
      data: {
        Permissions,
      },
    })
      .then(res => {
        const { data: { Result } = {} } = res;
        const availableMenuItemsHashList = {};
        Result.forEach(item => {
          availableMenuItemsHashList[item.URL] = item.Permission;
        });
        dispatch(getAvailableMenuItemsHashListSuccess(availableMenuItemsHashList));
        resolve(availableMenuItemsHashList);
      })
      .catch(() => {
        resolve('success');
      });
  });
};
